<template>
    <div>
        <router-view></router-view>
        <!-- <SpecimenPostureReportByCategory :category-id="$route.meta.category" year="2022" /> -->
    </div>
</template>

<script>
import SpecimenPostureReportByCategory from '../SpecimenPostureReportByCategory.vue';

export default {
    name: 'PosturasCategoryContainer',
    components: {
        SpecimenPostureReportByCategory
    }
}
</script>